.group-btn {
    display: flex;
    margin-bottom: 36px;
    overflow: hidden;
}

.group-btn input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.group-btn label {
    background-color: #e4e4e4;
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    padding: 8px 16px;
    margin-right: -1px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;
}

.group-btn label:hover {
    cursor: pointer;
}

.group-btn input:checked + label {
    background-color: #0d49ce;
    color: white;
    box-shadow: none;
}

.group-btn input + label {
    line-height: 48px;
}

.group-btn label:first-of-type {
    border-radius: 4px 0 0 4px;
}

.group-btn label:last-of-type {
    border-radius: 0 4px 4px 0;
}
