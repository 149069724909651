.rag_view__tree ul {
    margin: 8px 0 !important;
    margin-left: 32px !important;
}
.rag_view__tree ul > li {
    margin: 8px 0 !important;
}
.file > div {
    margin-left: 32px;
}

.wpg_view__tree ul {
    margin: 8px 0;
    margin-left: 32px;
}
.wpg_view__tree ul > li {
    margin: 8px 0;
}
